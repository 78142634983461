import React from "react"

/*** COMPONENTS ***/
import Main from "../../components/main"
import SEO from "../../components/seo"
import "../../styles/project.css"
import planetHeader from "../../images/ProcPlanet.png"

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes"

const PlanetPage = () => (
  <Main>
    <SEO title="Procedural Planet" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-Planet" src={planetHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">CARTOON PLANET</p>
          <p class="project-header-text-grey">PROCEDURAL METHODS</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Language</p>
        <p class="project-bulletpoint-text"> &#9642; C#</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2018-12-20</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          For this project, I created a procedural mesh and texture in the form
          of a planet in Unity. The mesh consists of triangles which can vary
          between 0 and 256 depending on what the user wishes. To create terrain
          on the sphere I created different layers with noise. I created one
          material containing two textures; one for the ocean and one for the
          land. Depending on the elevation, different textures are rendered.
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.PROJECTS}>
          &#8592; Back to projects
        </a>
      </div>
    </div>
  </Main>
)

export default PlanetPage
